import './css/App.css';
import Project from './Project';
//imgs
import kulturnattenImg from './images/kulturnatten.png'
import adventOfCodeIMG from './images/adventOfCodeIMG.png'
import flutterNamerImg from './images/flutterNamer.png'
import fixoImg from './images/fixo.png'
import ovldcars from './images/ovldcars.jpg'
//tool icons
import flutterLogo from './images/flutterLogo.png'
import dartLogo from './images/dartLogo.png'
import netLogo from './images/netLogo.png'
import jsLogo from './images/jsLogo.png'
import tsLogo from './images/tsLogo.png'
import htmlLogo from './images/htmlLogo.png'
import cssLogo from './images/cssLogo.png'
import tailwindLogo from './images/tailwindLogo.png'
import cSharpLogo from './images/Csharp_Logo.png'
import swishLogo from './images/swishLogo.png'
import sendGridLogo from './images/sendgridLogo.png'
import mssqlLogo from './images/mssqlLogo.png'
import luaLogo from './images/lua.png'

const RecentProjects = () => {
  var kulturnattenToolLogos: Array<{ img: string; text: string }> = [];
  kulturnattenToolLogos.push({ img: netLogo, text: ".NET" });
  kulturnattenToolLogos.push({ img: htmlLogo, text: "HTML" });
  kulturnattenToolLogos.push({ img: tailwindLogo, text: "TailWind" });
  kulturnattenToolLogos.push({ img: tsLogo, text: "TypeScript" });
  
  var aocToolLogos: Array<{ img: string; text: string }> = [];
  aocToolLogos.push({ img: cSharpLogo, text: "C#" });

  var driftMenuToolLogos: Array<{ img: string; text: string }> = [];
  driftMenuToolLogos.push({ img: luaLogo, text: "Lua" });

  var fixoToolLogos: Array<{ img: string; text: string }> = [];
  fixoToolLogos.push({ img: netLogo, text: ".NET" });
  fixoToolLogos.push({ img: mssqlLogo, text: "SQL Server" });
  fixoToolLogos.push({ img: swishLogo, text: "Swish" });
  fixoToolLogos.push({ img: sendGridLogo, text: "SendGrid" });
  return (
      <>
        <Project 
          Title='AdventOfCode solutions' 
          Description='My solutions for AdventOfCode.com coding problems' 
          LinkSvg={
            <svg fill="#E0E4E8" height="22px" width="22px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" stroke="#E0E4E8"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M172.211,41.609 c-24.934,27.119-44.68,66.125-56.755,111.992H49.749C75.179,102.741,118.869,62.524,172.211,41.609z M25.6,256 c0-26.999,5.077-52.727,13.662-76.8h70.494c-4.608,24.294-7.356,49.963-7.356,76.8s2.748,52.506,7.347,76.8H39.262 C30.677,308.727,25.6,283,25.6,256z M49.749,358.4h65.707c12.083,45.867,31.821,84.872,56.755,111.991 C118.869,449.476,75.179,409.259,49.749,358.4z M243.2,485.188c-43.81-8.252-81.877-58.24-101.359-126.788H243.2V485.188z M243.2,332.8H135.74c-4.924-24.166-7.74-49.997-7.74-76.8s2.816-52.634,7.74-76.8H243.2V332.8z M243.2,153.6H141.841 C161.323,85.052,199.39,35.063,243.2,26.812V153.6z M462.251,153.6h-65.707c-12.083-45.867-31.821-84.873-56.755-111.992 C393.131,62.524,436.821,102.741,462.251,153.6z M268.8,26.812c43.81,8.252,81.877,58.24,101.359,126.788H268.8V26.812z M268.8,179.2h107.46c4.924,24.166,7.74,49.997,7.74,76.8s-2.816,52.634-7.74,76.8H268.8V179.2z M268.8,485.188V358.4h101.359 C350.677,426.948,312.61,476.937,268.8,485.188z M339.789,470.391c24.934-27.127,44.672-66.125,56.755-111.991h65.707 C436.821,409.259,393.131,449.476,339.789,470.391z M402.244,332.8c4.608-24.294,7.356-49.963,7.356-76.8 s-2.748-52.506-7.347-76.8h70.494c8.576,24.073,13.653,49.801,13.653,76.8c0,27-5.077,52.727-13.662,76.8H402.244z"></path> </g> </g> </g></svg>
          } 
          Link='https://github.com/Antlin23/AdventOfCode'
          LinkText="See on GitHub"
          Image={adventOfCodeIMG}
          ToolIcons={aocToolLogos}
        />

        <Project 
          Title='FiveM mod menu' 
          Description='Mod menu (hack) for FiveM (GTA) with vehicle drift tune, counter & more made with Lua.' 
          LinkSvg={
            <svg viewBox="0 0 16 16" width="22" height="22" aria-hidden="true"><path fillRule="evenodd" fill="currentColor" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38C13.71 14.53 16 11.54 16 8c0-4.42-3.58-8-8-8z"></path></svg>
          } 
          Link='https://github.com/Antlin23/ModMenu'
          LinkText="See on GitHub"
          Image={ovldcars}
          ToolIcons={driftMenuToolLogos}
        />

        <Project 
          Title='kulturnatten.uppsala.se'
          Description='I was part of the dev team from start to finish'
          LinkSvg={
            <svg fill="#E0E4E8" height="22px" width="22px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" stroke="#E0E4E8"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M172.211,41.609 c-24.934,27.119-44.68,66.125-56.755,111.992H49.749C75.179,102.741,118.869,62.524,172.211,41.609z M25.6,256 c0-26.999,5.077-52.727,13.662-76.8h70.494c-4.608,24.294-7.356,49.963-7.356,76.8s2.748,52.506,7.347,76.8H39.262 C30.677,308.727,25.6,283,25.6,256z M49.749,358.4h65.707c12.083,45.867,31.821,84.872,56.755,111.991 C118.869,449.476,75.179,409.259,49.749,358.4z M243.2,485.188c-43.81-8.252-81.877-58.24-101.359-126.788H243.2V485.188z M243.2,332.8H135.74c-4.924-24.166-7.74-49.997-7.74-76.8s2.816-52.634,7.74-76.8H243.2V332.8z M243.2,153.6H141.841 C161.323,85.052,199.39,35.063,243.2,26.812V153.6z M462.251,153.6h-65.707c-12.083-45.867-31.821-84.873-56.755-111.992 C393.131,62.524,436.821,102.741,462.251,153.6z M268.8,26.812c43.81,8.252,81.877,58.24,101.359,126.788H268.8V26.812z M268.8,179.2h107.46c4.924,24.166,7.74,49.997,7.74,76.8s-2.816,52.634-7.74,76.8H268.8V179.2z M268.8,485.188V358.4h101.359 C350.677,426.948,312.61,476.937,268.8,485.188z M339.789,470.391c24.934-27.127,44.672-66.125,56.755-111.991h65.707 C436.821,409.259,393.131,449.476,339.789,470.391z M402.244,332.8c4.608-24.294,7.356-49.963,7.356-76.8 s-2.748-52.506-7.347-76.8h70.494c8.576,24.073,13.653,49.801,13.653,76.8c0,27-5.077,52.727-13.662,76.8H402.244z"></path> </g> </g> </g></svg>
          } 
          Link='https://kulturnatten.uppsala.se/'
          LinkText="View site"
          Image={kulturnattenImg}
          ToolIcons={kulturnattenToolLogos}
        />

        <Project 
          Title='Fixo' 
          Description='Marketplace for flea markets'
          LinkSvg={
            <svg viewBox="0 0 16 16" width="22" height="22" aria-hidden="true"><path fillRule="evenodd" fill="currentColor" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38C13.71 14.53 16 11.54 16 8c0-4.42-3.58-8-8-8z"></path></svg>
          } 
          Link='https://github.com/Antlin23/FleaMarket'
          LinkText="See on GitHub"
          Image={fixoImg}
          ToolIcons={fixoToolLogos}
        />
      </>
  );
}

/*
        <Project 
          Title='Word generator' 
          Description='Random word generator' 
          LinkSvg={
            <svg viewBox="0 0 16 16" width="22" height="22" aria-hidden="true"><path fillRule="evenodd" fill="currentColor" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38C13.71 14.53 16 11.54 16 8c0-4.42-3.58-8-8-8z"></path></svg>
          } 
          Link='https://github.com/Antlin23/wordGenerator'
          LinkText="See on GitHub"
          Image={flutterNamerImg}
          ToolIcons={wordGeneratorToolLogos}
        />
*/

export default RecentProjects;